function About() {
  return (
    <div className="hero ">
      <div className="text-center hero-content">
        <div className="max-w-lg">
          <p className="text-2xl mb-8 font-bold">
            Сошиал медиа хаягуудаа байршуулдаг сайт.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
