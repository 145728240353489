import { FaGithub } from "react-icons/fa";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import logo from "../../assets/logo.png";

function Navbar({ title }) {
  return (
    <nav className="navbar mb-12 shadow-lg bg-neutral text-neutral-content">
      <div className="container mx-auto">
        <div className="flex-none px-2 mx-2">
          <img src={logo} alt="Logo" className="inline pr-2 text-3xl" />
          <Link to="/" className="text-sm font-bold align-middle">
            {title}
          </Link>
        </div>
        <div className="flex-1 px-2 mx-2">
          <div className="flex justify-end">
            <Link
              to="/about"
              className="btn btn-ghost btn-md rounded-btn font-bold"
            >
              ТАНИЛЦУУЛГА
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

Navbar.defaultProps = {
  title: "Датабаз",
};

Navbar.propTypes = {
  title: PropTypes.string,
};

export default Navbar;
