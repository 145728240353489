import * as FontAwesome from "react-icons/fa";
import React from "react";

const Icon = (props) => {
  const { iconName, size, color } = props;
  const icon = React.createElement(FontAwesome[iconName]);
  return <div>{icon}</div>;
};

export default Icon;
