import profilePic from "../assets/profilePic.jpg";
import { FaTwitter } from "react-icons/fa";
import { createClient } from "@supabase/supabase-js";
import { useEffect, useState } from "react";
import Icon from "../Icon";

function Home() {
  const supabase = createClient(
    process.env.REACT_APP_SUPABASE_PROJ_URL,
    process.env.REACT_APP_SUPABASE_PROJ_KEY
  );

  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    async function getLinks() {
      setLoading(true);
      try {
        const linksData = await supabase.from("links").select();
        setLinks(linksData.data);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
    getLinks();
  }, []);

  return (
    <div className="hero ">
      <div className="text-center hero-content container mx-auto">
        <div className="max-w-lg">
          <div className="avatar">
            <div className="w-24 rounded-full">
              <img src={profilePic} alt="Profile" />
            </div>
          </div>

          <h1 className="text-5xl font-bold mb-3">Дөлгөөн</h1>
          <p className="text-3xl mb-8">сошиал медиа</p>

          {loading ? (
            <p>Түр хүлээнэ үү ...</p>
          ) : (
            <ul className="flex flex-col w-full">
              {links &&
                links.map((link, index) => (
                  <li
                    key={index}
                    className="cursor-pointer m-2 py-3 px-10 border-black border-2 text-slate-900 text-sm md:text-md"
                  >
                    <a
                      href={link.url}
                      className="flex items-center"
                      title={link.title}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon iconName={link.icon} />

                      <p className="ml-3 ">{link.title}</p>
                    </a>
                  </li>
                ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
